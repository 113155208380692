<template>
  <b-card title="设备管理">
    <div class="custom-search">

      <!-- advance search input -->
      <b-row class="mb-2">
        <b-col md="3">
          <b-form-group label-cols="4" label-cols-lg="3" label-size="lg" label="设备状态" label-for="input-lg">
            <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :value="queryParams.deviceStatus"
                      :options="deviceStatusOptions" v-model="queryParams.deviceStatus" class="w-100"
                      :reduce="val => val.value"
                      placeholder="请选择设备状态" @input="changeStatus"/>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label-cols="4" label-cols-lg="3" label-size="lg" label="设备ID" label-for="input-lg">
            <b-form-input id="input-lg" v-model="queryParams.deviceId" placeholder="请输入设备ID"/>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label-cols="4" label-cols-lg="4" label-size="lg" label="产品名称" label-for="input-lg">
            <b-form-input id="input-lg" v-model="queryParams.productName" placeholder="请输入产品名称"/>
          </b-form-group>
        </b-col>
        <!--        <b-col md="2">
                  <b-form-group label-cols="4" label-cols-lg="3" label-size="lg" label="机构" label-for="input-lg">
                    <b-form-input id="input-lg" v-model="queryParams.deviceId"  placeholder=""/>
                  </b-form-group>
                </b-col>-->
        <b-col md="5" class="tRight">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="searchButtonClick">
            搜索
          </b-button>
        </b-col>
      </b-row>
      <!--      <b-button class="mb-2" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" v-b-modal.modal-relation>
        批量关联
      </b-button>-->
    </div>

    <!-- table -->
    <vue-good-table :columns="columns" :rows="rows" :rtl="direction" :sort-options="{
          enabled: false,
        }" :search-options="{
      enabled: true,
      externalQuery: searchTerm
    }" :select-options="{
      enabled: true,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }" :pagination-options="{
      enabled: true,
      perPage: queryParams.pageSize
    }" theme="my-theme" @on-row-click="onRowClick" @on-cell-click="onCellClick" @on-select-all="onSelectAll"
    @on-row-dblclick="onRowDoubleClick">
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'deviceStatus'" class="text-nowrap">
          <span>{{ formatterDeviceStatus(props.row.deviceStatus) }}</span>
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" @click="onInfoClick(props.row)">
            <feather-icon icon="VoicemailIcon" class="mr-50"/>
            <span class="align-middle">查看</span>
          </b-button>

          <b-button v-if="props.row.canUp" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" @click="onUpClick(props.row)">
            <feather-icon icon="StarIcon" class="mr-50"/>
            <span class="align-middle">升级</span>
          </b-button>

        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="tCenter">
          <b-pagination  v-model="queryParams.currentPage" :total-rows="queryParams.total" :per-page="queryParams.pageSize" first-number
                        last-number
                        align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                        @change="currentPageHandleChange">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18"/>
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18"/>
            </template>
          </b-pagination>
        </div>
      </template>
    </vue-good-table>

    <!--    <b-modal id="modal-relation" centered ok-only ok-title="确定" @ok="sureSubmit">
      <b-form-group label-cols="4" label-cols-lg="3" label-size="lg" label="关联机构" label-for="input-lg">
        <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :value="roleFilter" :options="deviceStatusOptions"
                  class="w-100" :reduce="val => val.value" @input="(val) => $emit('update:roleFilter', val)"/>
      </b-form-group>
      <b-form-group label-cols="4" label-cols-lg="3" label-size="lg" label="渠道" label-for="input-lg">
        <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :value="roleFilter" :options="deviceStatusOptions"
                  class="w-100" :reduce="val => val.value" @input="(val) => $emit('update:roleFilter', val)"/>
      </b-form-group>
    </b-modal>-->

  </b-card>
</template>

<script>
import {
  BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol, BCard, BButton, BModal
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import vSelect from 'vue-select'
import request from '@/api/request'

export default {
  components: {
    BCard,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    vSelect,
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dir: false,
      deviceStatusOptions: [
        {
          value: 0,
          label: '未激活',
        }, {
          value: 1,
          label: '离线',
        }, {
          value: 2,
          label: '在线',
        }, {
          value: 3,
          label: '激活',
        }
      ],
      roleFilter: null,
      columns: [
        {
          label: '设备ID',
          field: 'deviceId',
        },
        {
          label: '产品名称',
          field: 'productName',
        },
        {
          label: '固件版本号',
          field: 'versionNumber',
        },
        {
          label: '设备状态',
          field: 'deviceStatus',
        },
        {
          label: '操作',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      //分页实体
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
        productName: null,
        deviceStatus: null,
        deviceId: null
      },
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getTableList()
  },
  methods: {
    onRowClick(params) {
      console.log(params.row)
    },
    onSelectAll(params) {
      console.log('all')
    },
    onRowDoubleClick(params) {
      console.log(params)
    },
    onCellClick(params) {
      console.log(params)
    },

    onInfoClick(row){
      this.$router.push({
        name: 'device-deviceInfo',
        query: { deviceConfigId: row.deviceConfigId }
      })
    },

    onUpClick(row){
      request.get('tob/bDevice/startOTA', {
        configId: row.deviceConfigId,
      })
        .then(res => {
          if (res.data.success) {
            this.getTableList()
          } else {
            that.$refs.loginForm.setErrors(res.data.msg)
          }
        })
    },

    changeStatus(val) {
      console.log(this.queryParams)
    },
    sureSubmit() {
      console.log('ok')
    },

    getTableList() {
      request.get('tob/bDevice/pageDevices', {
        pageSize: this.queryParams.pageSize,
        currentPage: this.queryParams.currentPage,
        productName: this.queryParams.productName,
        deviceStatus: this.queryParams.deviceStatus,
        deviceId: this.queryParams.deviceId
      })
          .then(res => {
            if (res.data.success) {
              this.rows = res.data.data.data
              this.queryParams.total = res.data.data.count
            } else {
              that.$refs.loginForm.setErrors(res.data.msg)
            }
          })
    },

    currentPageHandleChange(currentPage) {
      this.queryParams.currentPage = currentPage
      this.getTableList()
    },

    searchButtonClick() {
      this.queryParams.currentPage = 1
      this.getTableList()
    },

    formatterDeviceStatus(val) {
      for (let item of this.deviceStatusOptions) {
        if (item.value === val) {
          return item.label
        }
      }
      return val
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
